import { DefaultContext } from "@apollo/client";
import { GenModels } from "../../Components/Tools/utilities";
import { IframeApplyChangePostMessageTypes, IframePostMessageTypes } from "../../Constants/IframeConstants";
import { CameraVideoActions } from "../../Pages/Tool/useImageEditor";
import { PipelineSetting } from "../../types/graphql-global-types";
import { BackendEngineRequest } from "../briaAxios";
import { apiMoodSchema, isEmptySliderStateObj } from "./../../Components/Tools/Mood/data/sliders";
import { objectMapper } from "./../../Helpers/mapper";
import { fromQueryStringsToUrl } from "./../../Helpers/string";
import { ApiActions, BriaAPIConstants, BriaModelsParams } from "./briaAPIConstants";

import Scene, {
	AddSemanticCombinationVariables,
	APIAction,
	APIPipelineSetting,
	IframeConfig,
	ImageDevInfo,
	LeanImageInfo,
} from "./element";
import { translateText } from "../../GraphQL/mutations";
import i18n from "i18next";
import { IGroupOption } from "../../Components/Common/DropDown/ٍSingleSelectGroupedDropDown";
import { sendPostMessage } from "../../Helpers/iframe";

export interface ApiCall {
	action: ApiActions;
	input: any;
	response: any;
	shouldClearWatermark: boolean;
	orgUid: string;
	ignoreEmpty: boolean;
	disableRequest?: boolean;
}

class BriaAPI {
	private static _instances: Map<string, BriaAPI> = new Map();
	visualId;
	private selectedPresentersStyleSeed: number | undefined;
	private selectedUncropSeed: number | undefined;
	private selectedBackgroundReplaceSeed: number | undefined;
	private apiCallsMap: Map<ApiActions, ApiCall>;
	private apiCallsStack: ApiCall[];
	private iframeConfig: IframeConfig | null;

	private constructor(visual: string, iframeConfig: IframeConfig | null) {
		this.visualId = visual;
		this.apiCallsMap = new Map();
		this.apiCallsStack = [];
		this.selectedBackgroundReplaceSeed = undefined;
		this.selectedUncropSeed = undefined;
		this.selectedPresentersStyleSeed = undefined;
		this.iframeConfig = iframeConfig;
	}

	private fashionController = new AbortController();

	reset() {
		this.apiCallsMap = new Map();
		this.apiCallsStack = [];
		this.selectedBackgroundReplaceSeed = undefined;
		this.selectedUncropSeed = undefined;
		this.selectedPresentersStyleSeed = undefined;
	}

	resetCalls() {
		this.apiCallsMap = new Map();
		this.apiCallsStack = [];
	}

	cloneFromAnotherInstance(sourceInstance: BriaAPI) {
		this.setApiCallsMap(sourceInstance.getApiCallsMap());
		this.setApiCallsStack(sourceInstance.getApiCallsStack());
		this.setSelectedBackgroundReplaceSeed(sourceInstance.getSelectedBackgroundReplaceSeed());
		this.setSelectedUncropSeed(sourceInstance.getSelectedUncropSeed());
		this.setSelectedPresentersStyleSeed(sourceInstance.getSelectedPresentersStyleSeed());
	}

	clone() {
		const clonedInstance = new BriaAPI(this.visualId, this.iframeConfig);
		clonedInstance.setApiCallsMap(this.getApiCallsMap());
		clonedInstance.setApiCallsStack(this.getApiCallsStack());
		clonedInstance.setSelectedBackgroundReplaceSeed(this.getSelectedBackgroundReplaceSeed());
		clonedInstance.setSelectedUncropSeed(this.getSelectedUncropSeed());
		clonedInstance.setSelectedPresentersStyleSeed(this.getSelectedPresentersStyleSeed());
		return clonedInstance;
	}

	getApiCallsStack(): ApiCall[] {
		return [...this.apiCallsStack];
	}

	setApiCallsStack(apiCallStack: ApiCall[]) {
		this.apiCallsStack = apiCallStack;
	}

	getLastApiCall(ignoreAction?: ApiActions): ApiCall | null {
		let lastApiCall = null;
		if (this.apiCallsStack.length > 0) {
			for (let i = this.apiCallsStack.length - 1; i >= 0; i--) {
				lastApiCall = this.apiCallsStack[i];
				if (ignoreAction && i === 0 && lastApiCall.action === ignoreAction) {
					lastApiCall = null;
					break;
				}
				if (!ignoreAction || lastApiCall.action !== ignoreAction) {
					break;
				}
			}
		}
		return lastApiCall;
	}

	getLastApiCallSid(ignoreAction?: ApiActions): string | null {
		const lastCall = this.getLastApiCall(ignoreAction);
		return lastCall?.response?.data?.sid ?? null;
	}

	pushApiCall(
		action: ApiActions,
		input: any,
		response: any,
		shouldClearWatermark: boolean,
		orgUid: string,
		ignoreEmpty: boolean = true,
		disableRequest?: boolean,
		isNonSidCall?: boolean
	) {
		const apiCall = {
			input,
			response,
			action,
			shouldClearWatermark,
			orgUid,
			ignoreEmpty,
			disableRequest,
		};
		this.apiCallsMap.set(action, apiCall);
		if (!isNonSidCall) {
			this.apiCallsStack.push(apiCall);
		}
	}

	getApiCallsMap() {
		try {
			return new Map<ApiActions, ApiCall>(JSON.parse(JSON.stringify([...this.apiCallsMap])));
		} catch (e) {
			return new Map<ApiActions, ApiCall>();
		}
	}

	getChangesInputsMap() {
		const changes: Map<string, any> = new Map();
		if (this.apiCallsMap.has(ApiActions.FACE_MANIPULATIONS)) {
			const apiCall = this.apiCallsMap.get(ApiActions.FACE_MANIPULATIONS);
			for (const chng in apiCall?.input.changes) {
				const actions = apiCall?.input.changes[chng].actions;
				for (const key in actions) {
					changes.set(key, actions[key]);
				}
			}
		}
		if (this.apiCallsMap.has(ApiActions.REMOVE_OBJECT)) {
			const apiCall = this.apiCallsMap.get(ApiActions.REMOVE_OBJECT);
			changes.set(IframeApplyChangePostMessageTypes.RemoveObject, {
				action: apiCall?.input.objectsToRemoveArray,
			});
		}
		if (this.apiCallsMap.has(ApiActions.APPLY_MOOD)) {
			const apiCall = this.apiCallsMap.get(ApiActions.APPLY_MOOD);
			changes.set(IframeApplyChangePostMessageTypes.Mood, {
				action: apiCall?.input.changes,
			});
		}
		if (this.apiCallsMap.has(ApiActions.IMAGE_STYLE)) {
			changes.set(IframeApplyChangePostMessageTypes.ImageStyle, {
				action: {},
			});
		}
		if (this.apiCallsMap.has(ApiActions.REMOVE_BG)) {
			changes.set(IframeApplyChangePostMessageTypes.RemoveBackground, {
				action: {},
			});
		} else if (this.apiCallsMap.has(ApiActions.BLUR_BG)) {
			changes.set(IframeApplyChangePostMessageTypes.BlurBackground, {
				action: {},
			});
		} else if (this.apiCallsMap.has(ApiActions.REPLACE_BG)) {
			changes.set(IframeApplyChangePostMessageTypes.ReplaceBackground, {
				action: {
					seed: this.selectedBackgroundReplaceSeed,
				},
			});
		} else if (this.apiCallsMap.has(ApiActions.UNCROP)) {
			changes.set(IframeApplyChangePostMessageTypes.Uncrop, {
				action: {
					seed: this.selectedUncropSeed,
				},
			});
		} else if (this.apiCallsMap.has(ApiActions.REMOVE_BRUSH_OBJECT)) {
			const apiCall = this.apiCallsMap.get(ApiActions.REMOVE_BRUSH_OBJECT);
			changes.set(IframeApplyChangePostMessageTypes.RemoveObject, {
				action: apiCall?.input.changes,
			});
		} else if (this.apiCallsMap.has(ApiActions.REMOVE_AUTO_OBJECT)) {
			const apiCall = this.apiCallsMap.get(ApiActions.REMOVE_AUTO_OBJECT);
			changes.set(IframeApplyChangePostMessageTypes.RemoveObject, {
				action: apiCall?.input.changes,
			});
		} else if (this.apiCallsMap.has(ApiActions.RECAST_MODEL)) {
			changes.set(IframeApplyChangePostMessageTypes.PresentersStyle, {
				action: {
					seed: this.selectedPresentersStyleSeed,
				},
			});
		}

		return changes;
	}

	setApiCallsMap(apiCallsMap: Map<ApiActions, ApiCall>) {
		this.apiCallsMap = apiCallsMap;
	}

	setSelectedBackgroundReplaceSeed(seed: number | undefined) {
		this.selectedBackgroundReplaceSeed = seed;
	}

	setSelectedUncropSeed(seed: number | undefined) {
		this.selectedUncropSeed = seed;
	}

	setSelectedPresentersStyleSeed(seed: number | undefined) {
		this.selectedPresentersStyleSeed = seed;
	}

	getSelectedBackgroundReplaceSeed() {
		return this.selectedBackgroundReplaceSeed;
	}

	getSelectedUncropSeed() {
		return this.selectedUncropSeed;
	}

	getSelectedPresentersStyleSeed() {
		return this.selectedPresentersStyleSeed;
	}

	async addSemanticCombination(params: AddSemanticCombinationVariables): Promise<
		| {
				data: {
					thumbnail_images: string;
				};
		  }
		| any
	> {
		return BackendEngineRequest(
			{
				method: "post",
				url: `/add_semantic_combination`,
				data: JSON.stringify({
					ethnicity_value: params.ethnicityValue,
					visual_hash: this.visualId,
					object_type: "human",
					semantics: [],
					entity_id: params.entityId,
					face_paste_policy: "IF_NOT_CURRENT",
					ethnicity: params.ethnicity,
					pipeline_settings: [],
					thumbnails: params.thumbnails,
					scale_down_factor: params.scaleDownFactor,
				}),
			},
			"face-manipulation"
		);
	}

	static getInstance(visual: string = "", iframeConfig: IframeConfig | null, suffix?: string): BriaAPI {
		const key = suffix ? `${visual}_${suffix}` : visual;
		const instance = BriaAPI._instances.get(key) ?? new BriaAPI(visual, iframeConfig);
		BriaAPI._instances.set(key, instance);
		return instance;
	}

	static resetInstance(visual: string, iframeConfig: IframeConfig | null, suffix?: string) {
		const key = suffix ? `${visual}_${suffix}` : visual;
		BriaAPI._instances.set(key, new BriaAPI(visual, iframeConfig));
	}

	static setInstance(instance: BriaAPI, visual_id?: string) {
		BriaAPI._instances.set(visual_id ?? instance.visualId, instance);
	}

	_updateManipulations(
		input: any,
		actionType: ApiActions,
		shouldClearWatermark: boolean,
		orgUid: string,
		ignoreEmpty: boolean = true,
		disableRequest?: boolean
	) {
		if (!this.apiCallsMap.has(actionType)) {
			this.apiCallsMap.set(actionType, {
				input,
				response: null,
				action: actionType,
				shouldClearWatermark,
				orgUid,
				ignoreEmpty,
				disableRequest,
			});
		} else {
			const apiCallInput = this.apiCallsMap.get(actionType)?.input;
			for (let newChange of input.changes) {
				let oldChange = apiCallInput.changes.find((oldChange: any) => oldChange.id === newChange.id);
				if (oldChange) {
					oldChange.actions = {
						...oldChange.actions,
						...newChange.actions,
					};
				} else {
					apiCallInput.changes.push({ ...newChange });
				}
			}
		}
	}

	async _applyMoodOnImage(
		vhash: string,
		imageUrl: string | null,
		parameters: any,
		orgUid: string = "",
		abortController?: AbortController
	): Promise<{
		data: {
			image_res: string;
			sid: string;
		};
	}> {
		const mappedChanges = objectMapper(parameters ?? {}, apiMoodSchema);
		const body = {
			changes: [{ parameters: mappedChanges, id: vhash }],
			visual_id: vhash,
			visual_url: imageUrl,
		};

		const requestContext: DefaultContext = {
			fetchOptions: {
				signal: abortController?.signal,
			},
		};
		return BackendEngineRequest(
			{
				method: "post",
				url: `/apply_brand_mood`,
				data: JSON.stringify(body),
			},
			"brand-mood",
			requestContext
		);
	}

	async _applyStyle(
		sId: string | null,
		orgUid: string = ""
	): Promise<{
		data: {
			image_res: string;
			sid: string;
		};
	}> {
		let url = `${this.visualId}/${ApiActions.IMAGE_STYLE}`;

		return BackendEngineRequest(
			{
				method: "post",
				url: url,
				data: JSON.stringify({
					style: "lineart",
					...(sId && { sid: sId }),
				}),
			},
			"image-style"
		);
	}

	async _applyMood(
		vhash: string,
		sId: string | null,
		parameters: any,
		orgUid: string = "",
		abortController?: AbortController
	): Promise<{
		data: {
			image_res: string;
			sid: string;
		};
	}> {
		const mappedChanges = objectMapper(parameters ?? {}, apiMoodSchema);
		const body = {
			changes: [{ parameters: mappedChanges, id: vhash }],
			visual_id: vhash,
			...(sId && { sid: sId }),
		};

		const requestContext: DefaultContext = {
			fetchOptions: {
				signal: abortController?.signal,
			},
		};
		return BackendEngineRequest(
			{
				method: "post",
				url: `/apply_brand_mood`,
				data: JSON.stringify(body),
			},
			"brand-mood",
			requestContext
		);
	}

	async _pasteLogo(inputs: any, orgUid: string): Promise<any> {
		let params = `min_roi=${inputs?.min_roi}&logo_url=${inputs?.logo_url}&text_scale=${inputs?.scale_text}&mask_rect=${inputs?.mask_rect}`;
		return BackendEngineRequest(
			{
				method: "post",
				url: `${inputs?.vhash}/${ApiActions.PLACE_EMBEDDED_LOGO}?${params}`,
				data: JSON.stringify({}),
			},
			"paste-logo"
		);
	}

	async _recast_model(sId: string | null, inputs: any, orgUid: string = ""): Promise<any> {
		return BackendEngineRequest(
			{
				method: "post",
				url: `${this.visualId}/${ApiActions.RECAST_MODEL}?prompt=${inputs?.prompt}`,
				data: JSON.stringify({
					...(sId && { sid: sId }),
				}),
			},
			"presenter-style"
		);
	}

	async _remove_brushed_objects(
		changes: any,
		sId: string | null,
		isSystemAdmin: boolean | null = false
	): Promise<any> {
		try {
			return BackendEngineRequest(
				{
					method: "post",
					url: `${this.visualId}/${BriaAPIConstants.REMOVE_OBJECT}`,
					data: JSON.stringify({
						changes,
						enhance: true,
						...(sId && { sid: sId }),
					}),
				},
				"remove-object"
			);
		} catch (error) {
			console.log(error);
			return Promise.resolve("error");
		}
	}

	async _remove_auto_object(changes: any, sId: string | null, isSystemAdmin: boolean | null = false): Promise<any> {
		try {
			return BackendEngineRequest(
				{
					method: "post",
					url: `${this.visualId}/${BriaAPIConstants.REMOVE_OBJECT}`,
					data: JSON.stringify({
						changes,
						enhance: true,
						...(sId && { sid: sId }),
					}),
				},
				"remove-object"
			);
		} catch (error) {
			console.log(error);
			return Promise.resolve("error");
		}
	}

	async recallStackAfterLastAction(originalImageUrl: string, action: ApiActions, extraValue?: any) {
		const clonedInstance = this.clone();
		let itemIndex = -1;
		let response;
		const prevApiCallsStack = clonedInstance.getApiCallsStack();
		for (let i = prevApiCallsStack.length - 1; i >= 0; i--) {
			if (
				prevApiCallsStack[i].action === action &&
				(action !== ApiActions.REMOVE_OBJECT ||
					(action === ApiActions.REMOVE_OBJECT && prevApiCallsStack[i].input.objectId === extraValue))
			) {
				itemIndex = i;
				break;
			}
		}
		if (itemIndex !== -1) {
			let allSuccess = true;
			const apiCallsToRecall = prevApiCallsStack.splice(itemIndex, 9e9);
			const newApiCallsMap = clonedInstance.getApiCallsMap();
			newApiCallsMap.delete(action);
			clonedInstance.setApiCallsMap(newApiCallsMap);
			clonedInstance.setApiCallsStack(prevApiCallsStack);
			if (apiCallsToRecall.length === 1) {
				response = clonedInstance.getLastApiCall()?.response ?? {
					data: { image_res: originalImageUrl, sid: null },
				};
			} else {
				for (let y = 1; y < apiCallsToRecall.length; y++) {
					// exclude first call
					const apiCallToRecall = apiCallsToRecall[y];
					response = await clonedInstance.callApi(
						apiCallToRecall.action,
						apiCallToRecall.input,
						apiCallToRecall.shouldClearWatermark,
						apiCallToRecall.orgUid,
						apiCallToRecall.ignoreEmpty,
						apiCallToRecall.disableRequest,
						y === apiCallsToRecall.length - 1 // do nonSidCalls on the last item only
					);
					if (!response) {
						allSuccess = false;
						break;
					}
				}
			}
			if (allSuccess) {
				// all succeeded
				this.cloneFromAnotherInstance(clonedInstance);
			}
		}
		return response;
	}

	getLastImageUrl() {
		let imageUrl;
		if (this.hasNonSidCalls()) {
			imageUrl = this.getNonSidCalls().pop()?.response?.data?.image_res ?? undefined;
		} else {
			imageUrl = this.getLastApiCall()?.response?.data?.image_res ?? undefined;
		}
		return imageUrl;
	}

	getNonSidCalls() {
		return [this.getApiCallsMap().get(ApiActions.APPLY_MOOD)];
	}

	hasNonSidCalls() {
		return this.apiCallsMap.has(ApiActions.APPLY_MOOD);
	}

	async callNonSidCalls(
		sid: string | null,
		orgUid: string,
		shouldClearWatermark: boolean,
		disableRequest?: boolean,
		ignoreEmpty: boolean = true
	) {
		let response;
		if (this.apiCallsMap.has(ApiActions.APPLY_MOOD)) {
			const apiCallInput = this.apiCallsMap.get(ApiActions.APPLY_MOOD)?.input;
			if (!isEmptySliderStateObj(apiCallInput.changes)) {
				if (!disableRequest) {
					response = await this._applyMood(
						this.visualId,
						sid,
						apiCallInput.changes,
						shouldClearWatermark ? orgUid : ""
					);
					sendPostMessage(
						IframePostMessageTypes.ApiAction,
						{
							type: ApiActions.APPLY_MOOD,
						},
						this.visualId,
						this.iframeConfig
					);
				} else {
					response = {
						data: {
							image_res: apiCallInput.selectedImageUrl,
							sid: apiCallInput.sid,
						},
					};
				}

				this.apiCallsMap.set(ApiActions.APPLY_MOOD, {
					input: apiCallInput,
					response,
					action: ApiActions.APPLY_MOOD,
					shouldClearWatermark,
					orgUid,
					ignoreEmpty,
					disableRequest,
				});
			}
		}
		return response;
	}

	async callApi(
		action: ApiActions,
		input: any,
		shouldClearWatermark: boolean,
		orgUid: string = "",
		ignoreEmpty: boolean = true,
		disableRequest?: boolean,
		ignoreNonSidCalls?: boolean,
		isSystemAdmin?: boolean
	): Promise<any> {
		let response;
		//should only keep watermark for the current request. Meaning that the input of the last call to the engine should be an image without watermark
		//In case orgUid is NOT empty string, it takes priority
		let lastSid = this.getLastApiCallSid();

		if (action === ApiActions.FACE_MANIPULATIONS || action == ApiActions.FASHION_MEN) {
			for (let i = 0; i < input.changes.length; i++) {
				const actionKeys = Object.keys(input.changes[i].actions);
				if (ignoreEmpty) {
					// remove actions without value
					for (let y = 0; y < actionKeys.length; y++) {
						const action = input.changes[i].actions[actionKeys[y]];
						if (
							action.value === undefined ||
							action.value === null ||
							action.value === "" ||
							(actionKeys[y] === "diversity" && action.key === "original" && action.value === 0)
						) {
							delete input.changes[i].actions[actionKeys[y]];
						}
					}
				}

				// remove change if it has no actions
				if (actionKeys.length === 0) {
					input.changes.splice(i, 1);
					i--;
				}
				if (input.changes.length > 0) {
					this._updateManipulations(input, action, shouldClearWatermark, orgUid, ignoreEmpty, disableRequest);
				}
			}
		}

		if (action === ApiActions.REMOVE_OBJECT) {
			response = await this._removeObject(
				input.objectId,
				lastSid,
				shouldClearWatermark ? orgUid : "",
				isSystemAdmin
			);
		} else if (action == ApiActions.FASHION_MEN) {
			response = await this._createNewImage(
				input.changes,
				lastSid,
				input.apiPipelineSettings,
				BriaAPIConstants.CREATE_FASHION_ROUTE,
				shouldClearWatermark ? orgUid : ""
			);
		} else if (action === ApiActions.FACE_MANIPULATIONS) {
			// get only changes of the current face
			let faceChanges = input.changes;
			if (this.apiCallsMap.has(ApiActions.FACE_MANIPULATIONS)) {
				const passedChanges = [...faceChanges];
				input = this.apiCallsMap.get(ApiActions.FACE_MANIPULATIONS)?.input; // to get accumulated manipulations
				// get accumulated manipulations only for the faces inside the input object (selected face only)
				faceChanges = input.changes.filter((change: any) =>
					passedChanges.some((chg: any) => change.id === chg.id)
				);
			}

			let updatedSid = lastSid;
			let apiStackLenght = this?.getApiCallsStack()?.length ?? 0;
			for (let i = apiStackLenght - 1; i >= 0; i--) {
				if (this?.getApiCallsStack()[i]?.action !== ApiActions.FACE_MANIPULATIONS) {
					updatedSid = this?.getApiCallsStack()[i]?.response?.data?.sid;
					break;
				}
			}
			response = await this._createNewImage(
				faceChanges,
				updatedSid,
				input.apiPipelineSettings,
				BriaAPIConstants.CREATE_ROUTE,
				shouldClearWatermark ? orgUid : "",
				2000
			);
		} else if (action === ApiActions.UPPER_CLOTHES) {
			response = await this._upperClothChange(input.changes, lastSid, input.apiPipelineSettings);
		} else if (action === ApiActions.IMAGE_STYLE) {
			response = await this._applyStyle(lastSid, shouldClearWatermark ? orgUid : "");
		} else if (action === ApiActions.REMOVE_BG) {
			response = await this._removeImageBG(lastSid, shouldClearWatermark ? orgUid : "");
		} else if (action === ApiActions.BLUR_BG) {
			response = await this._blurImageBG(lastSid, shouldClearWatermark ? orgUid : "");
		} else if (action === ApiActions.REPLACE_BG) {
			if (this.selectedBackgroundReplaceSeed) {
				// us the seed to get the same BG
				response = await this._replaceImageBG(
					lastSid,
					input.changes,
					this.selectedBackgroundReplaceSeed,
					shouldClearWatermark ? orgUid : ""
				);
				response.data.image_res = response.data.result[0][0];
				response.data.sid = response.data.result[0][2];
			}
		} else if (action === ApiActions.UNCROP) {
			if (this.selectedUncropSeed) {
				// us the seed to get the same result
				response = await this._uncrop(
					lastSid,
					input.changes,
					this.selectedUncropSeed,
					shouldClearWatermark ? orgUid : ""
				);
				response.data.image_res = response.data.image_res;
				response.data.sid = response.data.sid;
			}
		} else if (action === ApiActions.PASTE_LOGO) {
			response = await this._pasteLogo(input.changes, orgUid);
		} else if (action === ApiActions.RECAST_MODEL) {
			if (this.selectedPresentersStyleSeed) {
				// us the seed to get the same result
				response = await this._recast_model(lastSid, input.changes, shouldClearWatermark ? orgUid : "");
				response.data.image_res = response.data.image_res;
				response.data.sid = response.data.sid;
			}
		} else if (action === ApiActions.REMOVE_BRUSH_OBJECT) {
			response = await this._remove_brushed_objects(
				input.changes,
				input.operation && input.operation === "reset" ? null : lastSid,
				isSystemAdmin
			);
		} else if (action === ApiActions.REMOVE_AUTO_OBJECT) {
			response = await this._remove_auto_object(input.changes, lastSid, isSystemAdmin);
		}

		this.pushApiCall(
			action,
			input,
			response,
			shouldClearWatermark,
			orgUid,
			ignoreEmpty,
			disableRequest,
			action === ApiActions.APPLY_MOOD
		);
		sendPostMessage(
			IframePostMessageTypes.ApiAction,
			{
				type: action,
			},
			this.visualId,
			this.iframeConfig
		);

		// should always be the last action
		if (!ignoreNonSidCalls) {
			const nonSidResp = await this.callNonSidCalls(
				response?.data?.sid ?? lastSid,
				orgUid,
				shouldClearWatermark,
				disableRequest,
				ignoreEmpty
			);
			if (nonSidResp) {
				response = nonSidResp;
			}
		}

		return response;
	}

	async increaseResolutionImage(
		sid: string,
		desiredIncrease: number,
		shouldClearWatermark: boolean,
		orgUid: string = ""
	): Promise<{ data: { image_res: string; sid: string } } | any> {
		let url = `${this.visualId}/${BriaAPIConstants.INCREASE_RESOLUTION}`;
		return BackendEngineRequest(
			{
				method: "get",
				url: url,
				data: JSON.stringify({
					...{ desired_increase: desiredIncrease },
					...(sid && { sid }),
				}),
			},
			"increase-res"
		);
	}

	async getDevInfo(timeout?: number): Promise<{
		data: ImageDevInfo;
	}> {
		const requestContext: DefaultContext = {
			fetchOptions: {
				timeout,
			},
		};
		return BackendEngineRequest(
			{
				method: "get",
				url: `${this.visualId}/${BriaAPIConstants.DEV_INFO_ROUTE}`,
			},
			"dev-info",
			requestContext
		);
	}

	async getObjectsMasks(timeout?: number): Promise<{ data: { objects_masks: string } } | any> {
		return BackendEngineRequest(
			{
				method: "post",
				url: `${this.visualId}/${BriaAPIConstants.MASK_GENERATOR}`,
				data: JSON.stringify(timeout),
			},
			"obj-masks"
		);
	}

	async getImageLeanInfo(timeout?: number): Promise<{
		data: LeanImageInfo;
	}> {
		const requestContext: DefaultContext = {
			fetchOptions: {
				timeout,
			},
		};
		return BackendEngineRequest(
			{
				method: "get",
				url: `${this.visualId}/${BriaAPIConstants.LEAN_IMAGE_INFO_ROUTE}?lean=true`,
			},
			"lean-info",
			requestContext
		);
	}

	async getPersonInfo(timeout?: number): Promise<{
		data: { scene: Scene[] };
	}> {
		const requestContext: DefaultContext = {
			fetchOptions: {
				timeout,
			},
		};
		return BackendEngineRequest(
			{
				method: "get",
				url: `${this.visualId}/${BriaAPIConstants.PERSON_INFO_ROUTE}`,
			},
			"person-info",
			requestContext
		);
	}

	async getObjectsInfo(timeout?: number): Promise<{
		data: Scene[];
	}> {
		const requestContext: DefaultContext = {
			fetchOptions: {
				timeout,
			},
		};
		return BackendEngineRequest(
			{
				method: "get",
				url: `${this.visualId}/${BriaAPIConstants.OBJECTS_INFO_ROUTE}`,
			},
			"objects-info",
			requestContext
		);
	}

	async getBackgroundInfo(timeout?: number): Promise<{
		data: Scene;
	}> {
		const requestContext: DefaultContext = {
			fetchOptions: {
				timeout,
			},
		};
		return BackendEngineRequest(
			{
				method: "get",
				url: `${this.visualId}/${BriaAPIConstants.BACKGROUND_INFO_ROUTE}`,
			},
			"bg-info",
			requestContext
		);
	}

	async getFashionInfo(): Promise<{
		data: {
			aligned_image_url: string;
			status: number;
		};
	}> {
		this.fashionController = new AbortController();
		const requestContext: DefaultContext = {
			fetchOptions: {
				signal: this.fashionController.signal,
			},
		};
		return BackendEngineRequest(
			{
				method: "get",
				url: `${this.visualId}/${BriaAPIConstants.FASHION_INFO_ROUTE}`,
			},
			"fashion-info",
			requestContext
		);
	}

	async _removeObject(
		objectId: string,
		sId: string | null,
		orgUid: string = "",
		isSystemAdmin: boolean | null = false
	): Promise<any> {
		try {
			const changes = [
				{
					id: objectId,
					actions: { remove: true },
				},
			];
			return BackendEngineRequest(
				{
					method: "post",
					url: `${this.visualId}/${BriaAPIConstants.REMOVE_OBJECT}`,
					data: JSON.stringify({
						changes,
						enhance: true,
						...(sId && { sid: sId }),
					}),
				},
				"remove-object"
			);
		} catch (error) {
			console.log(error);
			return Promise.resolve("error");
		}
	}

	async searchGallery(
		requestInfo: any,
		enableTranslation?: boolean
	): Promise<
		| {
				data: {
					results: {
						vhash: string;
						score: number;
						type?: string;
						url?: string;
						source_url?: string;
						sourceUrl?: string;
						mediumUrl?: string;
					}[];
				};
		  }
		| any
	> {
		if (enableTranslation) {
			requestInfo.query = await translateText(i18n.language, "en", requestInfo.query);
		}

		let url = `${BriaAPIConstants.SEARCH_ROUTE}${fromQueryStringsToUrl({
			...requestInfo,
		})}`;
		const resp = await BackendEngineRequest(
			{
				method: "get",
				url: url,
				data: JSON.stringify({}),
			},
			"search"
		);
		if (resp.data.code && resp.data.code !== 200) {
			return Promise.reject(resp);
		} else {
			return Promise.resolve(resp);
		}
	}

	async callGenerateApi(
		requestInfo: any,
		selectedModel?: IGroupOption,
		enableTranslation?: boolean
	): Promise<
		| {
				data: {
					result: {
						seed: number;
						urls?: string;
					}[];
				};
		  }
		| any
	> {
		const modelParams = BriaModelsParams[(selectedModel?.id as GenModels) ?? GenModels.bria_2_3_fast];
		const url = selectedModel?.extraData?.tailoredModel
			? `${BriaAPIConstants.BRIA_TAILORED}/${selectedModel?.id}`
			: `${BriaAPIConstants.TEXT_TO_IMAGE}/${modelParams.model_type}/${modelParams.model_version}`;

		if (enableTranslation) {
			requestInfo.prompt = await translateText(i18n.language, "en", requestInfo.prompt);
		}

		if (selectedModel?.extraData?.tailoredModel) {
			requestInfo.disable_safety_checker = true;
		}

		const resp = await BackendEngineRequest(
			{
				method: "post",
				url: url,
				data: JSON.stringify({
					...requestInfo,
				}),
			},
			"search"
		);
		if (
			resp.data &&
			resp.data.result &&
			resp.data.result.length > 0 &&
			resp.data.result[0].error_code &&
			resp.data.result[0].error_code !== "200"
		) {
			return Promise.reject(resp.data.result[0]);
		} else {
			return Promise.resolve(resp);
		}
	}

	async searchOnImage(requestInfo: any): Promise<
		| {
				data: {
					results: {
						visual_id: string;
						score: number;
						url: string;
						source_url?: string;
						sourceUrl?: string;
						mediumUrl?: string;
					}[];
				};
		  }
		| any
	> {
		let url = `${BriaAPIConstants.SEARCH_ON_IMAGE_ROUTE}${fromQueryStringsToUrl({
			...requestInfo,
		})}`;
		return BackendEngineRequest(
			{
				method: "get",
				url: url,
				data: JSON.stringify({}),
			},
			"search"
		);
	}

	async searchByImage(requestInfo: any): Promise<
		| {
				data: {
					results: {
						org_image_key: string;
						url: string;
						source_url: string;
						vhash: string;
						visual_id: string;
					}[];
				};
		  }
		| any
	> {
		let url = `${BriaAPIConstants.SEARCH_SIMILAR_IMAGES}${fromQueryStringsToUrl({
			...requestInfo,
		})}`;
		return BackendEngineRequest(
			{
				method: "get",
				url: url,
				data: JSON.stringify({}),
			},
			"search"
		);
	}

	async cancelFashionProcesses() {
		await this.fashionController.abort();
	}

	async _createNewImage(
		changes: {
			id: string;
			actions: APIAction;
			change_skin?: boolean;
		}[],
		sId?: string | null,
		apiPipelineSettings?: APIPipelineSetting[],
		create_route: string = BriaAPIConstants.CREATE_ROUTE,
		orgUid: string = "",
		timeout?: number
	): Promise<{
		data: {
			image_res: string;
			confidence: string;
			layers_url: string;
			sid: string;
		};
	}> {
		let requestContext: DefaultContext = {};
		if (create_route == BriaAPIConstants.CREATE_FASHION_ROUTE) {
			this.fashionController = new AbortController();
			requestContext = {
				fetchOptions: {
					signal: this.fashionController.signal,
					timeout: timeout,
				},
			};
		}

		return BackendEngineRequest(
			{
				method: "post",
				url: `${this.visualId}/${create_route}`,
				data: JSON.stringify({
					visual_id: this.visualId,
					...(sId && { sid: sId }),
					changes: changes,
					pipeline_settings: apiPipelineSettings,
				}),
			},
			"face-manipulation",
			requestContext
		);
	}

	convertToRealDataType(value: string | null | undefined) {
		if (value === null || value === undefined || value === "") {
			return value;
		} else if (!isNaN(Number(value))) {
			return Number(value);
		} else if (value.toLowerCase() === "true") {
			return true;
		} else if (value.toLowerCase() === "false") {
			return false;
		}
		return value;
		// TODO: Use GraphQL custom scalar instead
	}

	mapPipelineSettingsToApi(piplineSettings: PipelineSetting[]): APIPipelineSetting[] {
		const apiPipelineSetting: APIPipelineSetting[] = piplineSettings.map((piplineSetting) => {
			return {
				name: piplineSetting.name,
				description: piplineSetting.description,
				is_on: piplineSetting.isOn,
				value: this.convertToRealDataType(piplineSetting.value),
			};
		});
		return apiPipelineSetting;
	}

	mapPipelineInputToAPI(variables: any): APIAction {
		const actionList: APIAction = {};
		if (variables.semantics) {
			variables.semantics.forEach((semantic: any) => {
				if (semantic) {
					actionList[semantic.name] = {
						key: semantic.name,
						value: semantic.value,
					};
				}
			});
		}

		actionList["diversity"] = {
			key: variables.ethnicity,
			value: variables.ethnicityValue ?? 0,
		};

		if ("emotion" in variables && "sliderScale" in variables) {
			actionList["expression"] = {
				key: variables.emotion,
				value: variables.sliderScale,
			};
		}
		return actionList;
	}

	async video(
		visualId: [string],
		visualUrl: string,
		actions: CameraVideoActions
	): Promise<
		| {
				data: {
					video_response: string;
					confidence: string;
				};
		  }
		| any
	> {
		const url = `${visualId}/${BriaAPIConstants.VIDEO_ROUTE}`;
		return BackendEngineRequest(
			{
				method: "post",
				url: url,
				data: JSON.stringify({
					vhashes: visualId,
					image_url: visualUrl,
					changes: [
						{
							id: visualId,
							actions,
						},
					],
				}),
			},
			"video"
		);
	}

	static async extractColorPalette(visualUrl: string): Promise<
		| {
				data: {
					palette: string[];
				};
		  }
		| any
	> {
		const url = `extract_palette`;
		return BackendEngineRequest(
			{
				method: "post",
				url: url,
				data: JSON.stringify({ visual_url: visualUrl }),
			},
			"extract-color-palette"
		);
	}

	async _removeImageBG(
		sId: string | null,
		orgUid: string = ""
	): Promise<{ data: { image_res: string; sid: string } } | any> {
		let url = `${this.visualId}/remove_bg`;
		return BackendEngineRequest(
			{
				method: "get",
				url: url,
				data: JSON.stringify(
					orgUid || sId
						? {
								...(sId && { sid: sId }),
						  }
						: {}
				),
			},
			"remove-bg"
		);
	}

	async imageToPSD(
		sId: string | null,
		desired_increase_resolution?: number | null
	): Promise<{ data: { res_path: string } } | any> {
		const query = new URLSearchParams({
			...(sId && { sid: `${sId}` }),
			// ...(desired_increase_resolution && {
			// 	desired_increase_resolution: `${desired_increase_resolution}`,
			// }), // disable until engine timeout is solved
		}).toString();

		let url = `${this.visualId}/image_to_psd?${query}`;

		return BackendEngineRequest(
			{
				method: "post",
				url: url,
				data: JSON.stringify({}),
			},
			"img-to-psd"
		);
	}

	async _blurImageBG(
		sId: string | null,
		orgUid: string = ""
	): Promise<{ data: { image_res: string; sid: string } } | any> {
		let url = `${this.visualId}/background/blur`;
		return BackendEngineRequest(
			{
				method: "get",
				url: url,
				data: JSON.stringify(
					orgUid || sId
						? {
								...(sId && { sid: sId }),
						  }
						: {}
				),
			},
			"blur-bg"
		);
	}

	async _replaceImageBG(
		sId: string | null,
		inputs: any,
		seed?: number | null,
		orgUid: string = ""
	): Promise<{ data: { result: any[]; sid: string; image_res?: string } } | any> {
		let url = `${this.visualId}/${ApiActions.REPLACE_BG}`;

		return BackendEngineRequest(
			{
				method: "post",
				url: url,
				data: JSON.stringify({
					visual_id: this.visualId,
					bg_prompt: inputs?.bg_prompt,
					num_results: inputs?.num_results,
					fast: inputs?.fast,
					sync: false,
					disable_safety: true,
					...(seed && { seed }),
					...(sId && { sid: sId }),
					...(inputs?.allowSolidBg === false && { allow_solid_bg: false }),
				}),
			},
			"replace-bg"
		);
	}

	async _uncrop(
		sId: string | null,
		inputs: any,
		seed?: number | null,
		orgUid: string = ""
	): Promise<{ data: { sid: string; image_res: string; seed: number } } | any> {
		let url = `${this.visualId}/${ApiActions.UNCROP}`;

		return BackendEngineRequest(
			{
				method: "post",
				url: url,
				data: JSON.stringify({
					sync: false,
					visual_id: this.visualId,
					canvas_size: inputs?.canvas_size,
					original_image_size: inputs?.original_image_size,
					original_image_location: inputs?.original_image_location,
					...(seed && { seed }),
					...(sId && { sid: sId }),
					...(inputs?.prompt && { prompt: inputs?.prompt }),
				}),
			},
			"uncrop"
		);
	}

	async _upperClothChange(
		changes: [],
		sId: string | null,
		apiPipelineSettings?: APIPipelineSetting[]
	): Promise<
		| {
				data: {
					image_res: string;
					confidence: string;
					layers_url: string;
					sid: string;
				};
		  }
		| any
	> {
		return BackendEngineRequest(
			{
				method: "post",
				url: `${this.visualId}/${ApiActions.UPPER_CLOTHES}`,
				data: JSON.stringify({
					visual_id: this.visualId,
					changes: changes,
					pipeline_settings: apiPipelineSettings,
					...(sId && { sid: sId }),
				}),
			},
			"upper-cloth-change"
		);
	}
}

export default BriaAPI;
